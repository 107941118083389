import React, { useMemo } from 'react';
import FavoriteButton from '@atoms/FavoriteButton';
import { TypoXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import { useNeighbor } from '@hooks/useNeighbor';
import { useIsEarlyBirdActiveAndEligible } from '@utils/early-bird';
import routes, { route } from '@utils/routes';
import { pushDataLayer } from '@utils/spa-ga4';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import CoinInfoWithGuestsAndNights from '../CoinInfoWithGuestsAndNights';
import styles from './css';

type Props = {
  property: any;
  sizeString?: string;
  sliderType?: 'campaignProperties' | 'ranking' | 'planSelect' | 'lp' | null;
};

const PropertySliderItem = ({
  property,
  sizeString = '150px, (min-width: 680px) 280px',
  sliderType = null,
}: Props): JSX.Element => {
  const isEarlyBirdActiveAndEligible = useIsEarlyBirdActiveAndEligible();
  const coin = useMemo(
    () =>
      (isEarlyBirdActiveAndEligible &&
        (property.coin_range_each_rank_campaign?.[0] ||
          property.coin_each_rank_campaign)) ||
      property.coin_range_each_rank?.[0] ||
      property.coin_each_rank,
    [
      isEarlyBirdActiveAndEligible,
      property.coin_each_rank,
      property.coin_each_rank_campaign,
      property.coin_range_each_rank,
      property.coin_range_each_rank_campaign,
    ]
  );

  const { neighbor } = useNeighbor();
  const { pathname } = useRouter();

  const onClickProperty = () => {
    if (pathname === routes.inviteDetail) {
      pushDataLayer({
        campaign_code: '',
        event: 'gtm-click',
        event_name: `button_property-${property.id}`,
        neighbor_id: neighbor?.id,
      });
    }

    if (sliderType === 'campaignProperties') {
      pushDataLayer({
        campaign_code: '20240809_all_taiwan-hotel',
        event: 'gtm-click',
        event_name: `card_taiwan_${property.id}`,
        neighbor_id: neighbor?.id,
      });
    }
  };

  const { t } = useTranslation('property');

  return (
    <div className="swiper-slide property-slider-item">
      <Link
        href={route(routes.property, { id: property.id })}
        onClick={onClickProperty}
        target="_blank"
      >
        <div className="property-image-container">
          <div className="property-image">
            <Image
              alt={property.name}
              fill={true}
              sizes={sizeString}
              src={property.thumbnail_url}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
          {sliderType !== 'planSelect' && sliderType !== 'lp' && (
            <div className="favorite-button-container">
              <FavoriteButton propertyId={property.id} />
            </div>
          )}
        </div>
        <div className="property-box">
          <div className="property-box-inner">
            <h3 className="property-title">{property.name}</h3>
            {sliderType !== 'planSelect' && (
              <p className="property-content">{property.prefecture}</p>
            )}
            {property.coin ? (
              <CoinInfoWithGuestsAndNights
                coin={coin}
                propertyId={property.id}
              />
            ) : (
              <div className="property-no-rooms">
                <TypoXSBold text={t('noRooms')} />
              </div>
            )}
          </div>
        </div>
      </Link>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PropertySliderItem;
