import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .property-slider {
    .property-slider-container {
      :global(.swiper) {
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
          position: absolute;
          top: calc(50% - 24px);
          width: 40px;
          height: 40px;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          transition: all 0.3s;
          opacity: 0.35;
          &:hover {
            opacity: 1;
          }
        }
        :global(.swiper-button-disabled) {
          display: none;
        }
        :global(.swiper-button-next) {
          right: 32px;
        }
        :global(.swiper-button-prev) {
          left: 32px;
          @media (max-width: ${BREAKPOINT.desktop}px) {
            display: none;
          }
        }
      }
      .property-image-container {
        border-radius: 8px;
        overflow: hidden;
        .property-image {
          padding-top: 66%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          width: 100%;
        }
      }
      .property-box {
        margin-top: 12px;
        margin-left: 12px;
        margin-right: 12px;
        .property-title {
          font-size: 16px;
          color: #3b3c3e;
        }
        .property-content {
          font-size: 12px;
          color: #828284;
        }
      }
    }
  }
`;

export default styles;
