import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { desktopXl } = BREAKPOINT;

const styles = css`
  .section-header {
    padding: 24px 16px 16px;

    @media (min-width: ${desktopXl}px) {
      padding: 56px 16px 24px;
    }

    .section-header-heading {
      white-space: pre-wrap;
    }
  }
`;

export default styles;
