import React, { useState } from 'react';
import { TypoXS } from '@atoms/Typos';
import { useNeighbor } from '@hooks/useNeighbor';
import { coinTabRanges } from '@utils/constants';
import { pushDataLayer } from '@utils/spa-ga4';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  getPropertiesByCoin: (locale: string | undefined, coinTabKey: string) => void;
};

const CoinTabs = ({ getPropertiesByCoin }: Props): JSX.Element => {
  const { locale } = useRouter();
  const { t } = useTranslation('browse');
  const [selectedCoinKey, setSelectedCoinKey] = useState(
    Object.keys(coinTabRanges)[0]
  );
  const { neighbor } = useNeighbor();

  const onClickTab = (coinTabKey: string) => {
    setSelectedCoinKey(coinTabKey);
    getPropertiesByCoin(locale, coinTabKey);
    pushDataLayer({
      campaign_code: '',
      event: 'gtm-click',
      event_name: `button_property-${coinTabKey}`,
      neighbor_id: neighbor?.id,
    });
  };

  return (
    <div className="coin-tabs">
      {Object.keys(coinTabRanges).map((coinTabRange) => {
        const isSelected = coinTabRange === selectedCoinKey;

        return (
          <button
            key={coinTabRange}
            className={classNames('area-tab-button', {
              selected: isSelected,
            })}
            onClick={() => onClickTab(coinTabRange)}
            type="button"
          >
            <TypoXS
              color={isSelected ? 'black900' : 'black600'}
              text={t(`coinTabs.${coinTabRange}`)}
            />
          </button>
        );
      })}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default CoinTabs;
