import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .coin-tabs {
    overflow: scroll;
    white-space: nowrap;
    margin-bottom: 8px;
    padding-bottom: 8px;
    line-height: 32px;
    padding-left: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
    .area-tab-button {
      margin-right: 8px;
      padding: 4px 12px;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        background: ${COLORS.black200};
        border-radius: 20px;
      }
      &.selected,
      &:focus {
        background: ${COLORS.black300};
        border-radius: 20px;
      }
    }
  }
`;

export default styles;
